import _vm from '@/main'

export default {
    base64encode(str){
        return window.btoa(unescape(encodeURIComponent(str)));
    },
    base64decode(str){
        str = str.replace(/\s/g, '');
        return decodeURIComponent(escape(window.atob( str )));
    },
    toastSuccess(msg,title="Bilgi"){
        _vm.$toast.add({severity:'success', summary: title, detail:msg, life: 3000});
    },
    toastWarn(msg,title="Uyarı"){
        _vm.$toast.add({severity:'warn', summary: title, detail:msg, life: 3000});
    },
    toastError(msg,title="Hata"){
        _vm.$toast.add({severity:'error', summary: title, detail:msg, life: 3000});
    },
    formatDate(date,format='DD-MM-YYYY'){
        if(!date) return "--*--";
        //DD-MM-YYYY HH:mm:ss
        return _vm.$moment(date).format(format);
    },
    formatDateUseTimeZone(date,format='DD-MM-YYYY',tz='Europe/Istanbul') {
        //DD-MM-YYYY HH:mm:ss
        if(!date) return "--*--";
        return _vm.$moment.utc(date).tz(tz).format(format)
    },
    cleanSource(source){
        return JSON.parse(JSON.stringify(source));
    },
    findIndexByIdx(id,array,elm="idx"){
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (array[i][elm] === id) {
                index = i;
                break;
            }
        }
        return index;
    },
    round(value, precision) {
        if (Number.isInteger(precision)) {
            let shift = Math.pow(10, precision);
            // Limited preventing decimal issue
            return (Math.round( value * shift + 0.00000000000001 ) / shift);
        } else {
            return Math.round(value);
        }
    },
    getAmountTaxInclusive(amount=0,tax_ratio=0){
        return Number(amount) * (1+Number(tax_ratio)/100)
    },
    getAmountTaxExclusive(amount=0,tax_ratio=0){
        return Number(amount) / (1+Number(tax_ratio)/100)
    },
    getTaxAmount(amount=0,tax_ratio=0){
        return Number(amount) * Number(tax_ratio) / 100
    },
    //indirim tutarı
    getDiscountAmount(amount=0,discount=0){
        return amount * discount / 100;
    },
    //indirimli tutar
    getDiscountedAmount(amount=0,discount=0){
        return amount - (amount * discount / 100);
    },
    formatCurrency(value,hideDigits=true) {
        if(hideDigits && Number(value) % 1 === 0){
            return Number(value).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'}).slice(0, -3);
        }else{
            return Number(value).toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'});
        }
    },

    token_parser(token) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload).data;
    },
    parseURL(url = window.location.href){
        let parsed_url = {};
        let protocol_i;
        let remaining_url;
        let domain_i;
        let domain_parts;
        //##############
        if ( url == null || url.length == 0 )
            return parsed_url;
        //##############
        protocol_i = url.indexOf('://');
        parsed_url.protocol = url.substr(0,protocol_i);
        //##############
        if(protocol_i === -1){
            remaining_url = url.substr(0, url.length);
        }else{
            remaining_url = url.substr(protocol_i + 3, url.length);
        }
        //##############
        domain_i = remaining_url.indexOf('/');
        domain_i = domain_i == -1 ? remaining_url.length - 1 : domain_i;
        parsed_url.domain = remaining_url.substr(0, domain_i).split(":")[0];
        parsed_url.port   = remaining_url.substr(0, domain_i).split(":")[1] === undefined ? null : remaining_url.substr(0, domain_i).split(":")[1];
        parsed_url.path   = domain_i == -1 || domain_i + 1 == remaining_url.length ? null : remaining_url.substr(domain_i + 1, remaining_url.length);
        //##############
        domain_parts = parsed_url.domain.replace("www.","").split('.');
        switch ( domain_parts.length ){
            case 2:
                parsed_url.subdomain = null;
                parsed_url.host = domain_parts[0];
                parsed_url.tld = domain_parts[1];
                break;
            case 3:
                parsed_url.subdomain = domain_parts[0];
                parsed_url.host = domain_parts[1];
                parsed_url.tld = domain_parts[2];
                break;
            case 4:
                parsed_url.subdomain = domain_parts[0];
                parsed_url.host = domain_parts[1];
                parsed_url.tld = domain_parts[2] + '.' + domain_parts[3];
                break;
        }
        parsed_url.parent_domain = parsed_url.host + '.' + parsed_url.tld;
        return parsed_url;
    }
};