import http from '@/http-common';
import router from "@/router";
import fnc from '@/fnc';



export const routes = {
    path: '/dashboard',
    name: 'app',
    component: () => import('@/views/App'),
    children : [
        {
            path: '',
            name:'Dashboard',
            component: () => import('@/views/Dashboard')
        },
        {
            path: '/ticketTransactions',
            name:'TicketTransactions',
            component: () => import('@/components/ticket/TicketTransactions')
        },
    ]
};


export default {
    namespaced:true,
    state: {
        token       : localStorage.getItem('token') || false,
        userData    : localStorage.getItem('token') ? fnc.token_parser(localStorage.getItem('token')) : [],
        modules     : null,
        ticketNoti  : []
    },
    actions:{
        setLogin({ commit }, tokenData){
            return commit('LOGIN_SUCCESS', tokenData);
        },
        login({commit}, formData){
            return http.post('auth/login',formData).then(res => {
                if(res.success){
                    commit('LOGIN_SUCCESS',res.response);
                }else{
                    commit("LOGOUT");
                }
                return Promise.resolve(res);
            });
        },
        changeRole({commit}, userRoleIdx){
            return http.post('auth/changeRole',{userRoleIdx:userRoleIdx}).then(res => {
                if(res.success){
                    commit('LOGIN_SUCCESS',res.response);
                    fnc.toastSuccess(res.message);
                }else{
                    fnc.toastError(res.message);
                }
                return Promise.resolve(res);
            });
        },
        menusAndRoutes({state,commit}){
            state.modules = null;
            http.post('auth/menusAndRoutes',{}).then(res => {
                state.modules  = res.response.menus;
                commit("SET_ROUTE",res.response.routes);
            });
        },
        getTicketNoti({state}){
            state.ticketNoti = [];
            http.post('ticket/getTicketNoti',{}).then(res => {
                state.ticketNoti  = res.response;
            });
        },
        deleteTicketNoti({state},noti){
            if(noti.isFixed === false){
                http.post('ticket/deleteTicketNoti',{ticketIdx:noti.ticketIdx}).then(res => {
                    if(res.success){
                        state.ticketNoti = state.ticketNoti.filter(val => val.ticketIdx !== noti.ticketIdx);
                    }
                });
            }
        },
        logout({commit},silent=false){
            commit("LOGOUT");
            if(!silent){
                fnc.toastSuccess("Çıkış İşlemi Yapıldı");
            }
            return Promise.resolve(true);
        },
        isLoginValid({state}){
            return Promise.resolve(!(!state.token || (state.userData.exp && Date.now() >= state.userData.exp * 1000)));
        }
    },
    mutations:{
        LOGIN_SUCCESS(state,tokenData){
            localStorage.setItem('token',tokenData);
            state.token         = tokenData;
            state.userData      = fnc.token_parser(tokenData);
        },
        LOGOUT(state){
            localStorage.removeItem('token');
            state.token         = false;
            state.userData      = [];
        },
        SET_ROUTE(state,routerData){
            routerData.forEach((item) =>{
                let params = item.routeParams ? item.routeParams : "";
                let newRoute = {
                    path: `${item.routePath}${params}`,
                    name: `${item.routeName}`,
                    component: () => import('@/views/'+item.component)
                };
                routes.children.push(newRoute);
            });
            router.addRoute(routes);
        }
    },
    getters:{
        getToken           : (state) => state.token,
        userData           : (state) => state.userData,
        modules            : (state) => state.modules,
        ticketNoti         : (state) => state.ticketNoti,
        isLoggedIn         : (state) => !(!state.token || (state.userData.exp && Date.now() >= state.userData.exp * 1000)),
    }


}