<template>
    <div class="progress-spinner" :style="Style2" v-if="isLoading">
        <ProgressSpinner :style="Style" strokeWidth="2" animationDuration=".9s"/>
    </div>
</template>

<script>
    import ProgressSpinner from 'primevue/progressspinner';
    export default {
        name: "Loader",
        props: {
            isLoading: Boolean,
            size: {
                type: Number,
                default: 100
            },
            position:{
                type: String,
                default: "absolute"
            }
        },
        computed: {
            Style() {
                return `width:${this.size}px; height:${this.size}px`;
            },
            Style2() {
                return `min-height:60px; position:${this.position}`;
            }
        },
        components: { ProgressSpinner }
    }
</script>

<style scoped>

</style>