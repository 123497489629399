import { createApp } from 'vue'
import router from "@/router";
import store from './store/store';
import Main from './Main.vue'
import moment from 'moment-timezone';
import wait from "@meforma/vue-wait-for";
//import i18n from './i18n'
import conf from './conf';
import fnc from './fnc';

import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import Message from 'primevue/message';
import ScrollTop from 'primevue/scrolltop';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Ripple from 'primevue/ripple';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import InputMask from 'primevue/inputmask';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import ToggleButton from 'primevue/togglebutton';
import Loader from "@/components/Loader";
import WaitComp from './components/Wait';

moment.locale('tr');
import CKEditor from '@ckeditor/ckeditor5-vue';
const app = createApp(Main);
app.use(wait);
app.use(router);
app.use(store);
app.use(CKEditor);
//app.use(i18n);
app.use(PrimeVue, {
    ripple: true,
    locale: {
        startsWith: 'İle başlayan',
        contains: 'Benzeyen',
        notContains: 'Benzemeyen',
        endsWith: 'İle biten',
        equals: 'Eşit olan',
        notEquals: 'Eşit olmayan',
        noFilter: 'Filtre yok',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Temizle',
        apply: 'Uygula',
        matchAll: 'Tümünü eşleştir',
        matchAny: 'Herhangi birini eşleştir',
        addRule: 'Kural ekle',
        removeRule: 'Kural sil',
        accept: 'Evet',
        reject: 'Hayır',
        choose: 'Seç',
        upload: 'Yükle',
        cancel: 'İptal',
        dayNames: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
        dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
        dayNamesMin: ["Pz","Pt","Sa","Ça","Pe","Cu","Cm"],
        monthNames: ["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"],
        monthNamesShort: ["Ock", "Şub", "Mar", "Nis", "May", "Haz","Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
        today: 'Bugün',
        weekHeader: 'Wk',
        firstDayOfWeek: 1,
        dateFormat: 'dd-mm-yy',
        weak: 'Hafta',
        medium: 'Orta',
        strong: 'Güçlü',
        passwordPrompt: 'Şifre gir',
        emptyFilterMessage: 'Sonuç bulunamadı',
        emptyMessage: 'Mevcut seçenek yok'
    },
});
app.use(ToastService);
app.use(ConfirmationService);

app.component("DataTable",DataTable);
app.component("Message",Message);
app.component("ScrollTop",ScrollTop);
app.component("Column",Column);
app.component("InputText",InputText);
app.component("InputNumber",InputNumber);
app.component("InputMask",InputMask);
app.component("RadioButton",RadioButton);
app.component("Checkbox",Checkbox);
app.component("Dropdown",Dropdown);
app.component("MultiSelect",MultiSelect);
app.component("Textarea",Textarea);
app.component("Button",Button);
app.component("Dialog",Dialog);
app.component("ConfirmDialog",ConfirmDialog);
app.component("Toast",Toast);
app.component("Calendar",Calendar);
app.component("OverlayPanel",OverlayPanel);
app.component("ToggleButton",ToggleButton);
app.component("Loader",Loader);
app.component("Wait",WaitComp);

app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

app.config.globalProperties.$conf   = conf;
app.config.globalProperties.$fnc    = fnc;
app.config.globalProperties.$moment = moment;

app.mount('#app');

const _vm = app.config.globalProperties;
export default _vm;

import './assets/css/main.css';
//import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

