<template>
    <div>
        <Loader :isLoading="isLoading" :position="'fixed'" />
        <ConfirmDialog></ConfirmDialog>
        <Toast/>
        <div v-if="webData">
            <router-view />
        </div>
        <div v-if="!webData && !isLoading" class="text-center pt-5">
            <img src="@/assets/img/connect-error.png"/>
            <h3>Bağlantı sırasında bir hata oluştu.</h3>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Main",
        data(){
            return {
                isLoading : false
            }
        },
        async created(){
            this.isLoading = true;
            await this.$store.dispatch("core/getWebData").then(res=>{
                if(res.success){
                    window.document.title = res.response.companyName;
                }
            });
            this.isLoading = false;
        },
        computed: {
            webData() {
                return this.$store.getters['core/webData'];
            }
        },
    }
</script>

<style scoped>

</style>