<template>
    <slot v-if="waitsFor" name="loading" >
        <div class="p-6 text-center" style="position: relative; min-height: 100px;">
            <Loader :size="loaderSize" :isLoading="true"/>
            <div v-if="icon"><i style="font-size: 5em;" :class="['pi',icon]" /></div>
            <div v-if="loaderMessage" class="mt-2">{{loaderMessage}}</div>
        </div>
    </slot>
    <slot v-else></slot>
</template>

<script>
    export default {
        name: "wait",
        props:{
            for:{
                type: String,
                default: null
            },
            icon:{
                type: String,
                default: "pi-clock"
            },
            loaderSize: {
                type: Number,
                default: 100
            },
            loaderMessage: {
                type: String,
                default: null
            },
        },
        computed: {
            waitsFor() {
                return this.$wait.is(this.for);
            }
        }
    }
</script>

<style scoped>

</style>