import {createStore } from "vuex";
//---------
import auth from "./modules/auth";
import core from "./modules/core";

export default createStore({
    modules:{
        auth,
        core
    }

})