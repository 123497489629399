import conf from "@/conf";
import _vm from '@/main'
import axios from 'axios';

const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
};

const con = axios.create({
    baseURL: conf.api_url,
    headers: headers
});

con.interceptors.request.use(function (config) {
    if(localStorage.getItem('token')){
        config.headers.Authorization = localStorage.getItem('token');
    }
    return config;
});
con.interceptors.response.use(
    response => {
        if(typeof response.data === 'object'){
            if(!response.data.success){
                if(response.data.msgCode === 101){
                    window.location.href = "repair.html";
                }
                if(response.data.msgCode === 100){
                    _vm.$store.dispatch('auth/logout',true).then(()=>{
                        _vm.$router.push({name:"Login",params:{msg:response.data.message}});
                    });
                }else{
                    if(!response.data.response){
                        _vm.$fnc.toastWarn(response.data.message);
                    }
                }
            }
            return Promise.resolve(response.data);
        }else{
            return Promise.reject(response.data);
        }
    },
    error => {
        return Promise.reject(error);
    });

let http = {
    post: function(serviceName, params) {
        return con.post(serviceName,params).catch(data=>{
            _vm.$fnc.toastError(data);
            return Promise.reject(data);
        });
    },
};

export default http;