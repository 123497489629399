import http from '@/http-common';

export default {
    namespaced:true,
    state: {
        webData     : null,
    },
    actions:{
         getWebData({commit}){
            return http.post('api/webData',{}).then(res => {
                commit('SET_WEB_DATA',res.response);
                return Promise.resolve(res.response);
            });
        },

    },
    mutations:{
        SET_WEB_DATA(state,webData){
            state.webData         = webData;
        },
    },
    getters:{
        webData           : (state) => state.webData,
    }


}