import fnc from "@/fnc";

let base_url;
let url = fnc.parseURL();
base_url = url.protocol || url.protocol !== "" ? url.protocol+"://" : "";
base_url += url.domain;
base_url += url.port !== null && url.port !== "" ? ":"+url.port : "";
base_url += "/";
let conf = {
    api_url         : base_url+"api/",
    router_base     : "/",
    base_url        : base_url+""
};

if (process.env.NODE_ENV !== "production") {
    conf.api_url        = "http://school.altelk.com:26711/altTicketNewApi/";
    conf.router_base    = "";
    conf.base_url       = base_url;
}
export default conf