import { createWebHistory, createRouter } from "vue-router";
import conf from "./conf";

const router = createRouter({
    history: createWebHistory(conf.router_base),
    routes :[
        {
            path: "/login",
            name:'Login',
            component: () => import('./views/Login')
        },
        {
            path: "/resetPassword/:token",
            name: "ResetPassword",
            component: () => import('./views/ResetPassword')
        },
        {
            path: "/forgotPassword",
            name: "ForgotPassword",
            component: () => import('./views/ForgotPassword')
        },
        {
            path: '/dashboard',
            name: 'app',
            component: () => import('@/views/App'),
            children : [
                {
                    path: '',
                    name:'Dashboard',
                    component: () => import('@/views/Dashboard')
                },
            ]
        },
        { path: '/', redirect:"/login" },
        { path: '/:pathMatch(.*)*', redirect: '/dashboard' }
    ],
});

/*router.beforeEach(async(to, from, next) => {
    let exp = false;
    if(store.state.auth.is_login){
        await store.dispatch('auth/checkTokenTimeOut').then((status) => exp = status );
        if(exp){
            store.dispatch('auth/logout', {isExp: true});
            next("/login");
        }else{
            next();
        }
    }else{
        next();
    }
    window.scrollTo(0, 0);
});*/

export default router;